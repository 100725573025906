// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '@shared/config/routes/constants';
import { DateTime } from 'luxon';
import IconBack from '@shared/assets/Icons/icon_back.svg';
import IconEdit from '@shared/assets/Icons/icon_edit_violet.svg';
import IconPhone from '@shared/assets/Icons/icon_phone_small.svg';
import IconEmail from '@shared/assets/Icons/icon_letter_small.svg';
import IconChat from '@shared/assets/Icons/icon_chat_small.svg';
import IconFlagEn from '@shared/assets/Icons/flag_en.svg';
import IconFlagDe from '@shared/assets/Icons/flag_de.svg';
import IconFlagFr from '@shared/assets/Icons/flag_fr.svg';
import IconFlagPt from '@shared/assets/Icons/flag_pt.svg';
import IconFlagLb from '@shared/assets/Icons/flag_lb.svg';
import IconPDF from '@shared/assets/Icons/icon_pdf.svg';
import IconPaper from '@shared/assets/Icons/icon_paper.svg';
import IconCalendar from '@shared/assets/Icons/icon_calendar.svg';
import IconClock from '@shared/assets/Icons/icon_clock.svg';
import IconWarning from '@shared/assets/Icons/icon_warning.svg';
import IconScheduled from '@shared/assets/Icons/icon_status-scheduled.svg';
import IconInProgress from '@shared/assets/Icons/icon_status-inprogress.svg';
import IconDone from '@shared/assets/Icons/icon_status-done.svg';
import { Box, Button, Collapse, FormControl, IconButton, MenuItem, Select, type SxProps } from '@mui/material';
import ClientsMap from '@widgets/DetailServiceRequestWidget/ui/ClientsMap';
import { useServiceRequest } from '@widgets/DetailServiceRequestWidget/model/useServiceRequest';
import { useServiceRequestUpdate } from '@widgets/DetailServiceRequestWidget/model/useServiceRequestUpdate';
import SliderPhoto from '@widgets/DetailServiceRequestWidget/ui/SliderPhoto';
import VideoPlayer from '@widgets/DetailServiceRequestWidget/ui/VideoPlayer';
import {
  PERSON_TITLE,
  TYPE_OF_WORK_LIST,
} from '@widgets/DetailServiceRequestWidget/config/constants';
import { ScrollToTopOnMount } from '@shared/ui/components/ScrollToTopOnMount';
import { APIProvider } from '@vis.gl/react-google-maps';
import MakeAppointmentWidget from '@widgets/MakeAppointmentWidget';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import IconArrowDown from '@shared/assets/Icons/icon_arrow_down.svg?react';
import { useReport } from '@widgets/DetailServiceRequestWidget/model/useReport';
import PreliminaryInvoiceWidget from '@widgets/PreliminaryInvoiceWidget';
import { useFetchProducts } from '@widgets/MakeAppointmentWidget/model/useFetchProducts';
import CreateInvoiceWidget from '@widgets/CreateInvoiceWidget';
import { useCreateInvoice } from '@widgets/CreateInvoiceWidget/model/useCreateInvoice';
import { useGetInvoice } from '@widgets/CreateInvoiceWidget/model/useGetInvoice';
import { useGenerateReport } from '@widgets/DetailServiceRequestWidget/model/useGenerateReport';
import { ModalWindow } from '@widgets/CreateServiceRequestWidget/ui';

const languages = {
  en: IconFlagEn,
  de: IconFlagDe,
  fr: IconFlagFr,
  lb: IconFlagLb,
  pt: IconFlagPt,
} as Record<string, string>;

const statusIcons = {
  SCHEDULED: IconScheduled,
  FOR_EXECUTION: IconScheduled,
  IN_PROGRESS: IconInProgress,
  COMPLETED: IconDone,
} as Record<string, string>;

const todoTypes = {
  PRE_SERVICE_ANALYSIS: 'Pre-Service Analysis',
  SERVICE: 'Service In-Progress',
  POST_SERVICE_REPORT: 'Post-Service Report',
} as Record<string, string>;

const timeTypes = {
  COMMUTING_TIME: 'Commuting time',
  PRE_SERVICE_ANALYSIS: 'Pre-service analysis',
  SERVICE: 'Service in-progress',
  POST_SERVICE_REPORT: 'Post-service',
  TOTAL_TIME: 'Total time',
} as Record<string, string>;

interface ITableCellProps {
  text: string
  align?: 'left' | 'right' | 'center' | 'inherit' | 'justify' | undefined
  sx?: SxProps | undefined
}

const DetailServiceRequestWidget: React.FC = () => {
  const [selectedType, setSelectedType] = useState('empty');

  const navigate = useNavigate();
  const { id } = useParams();
  const { data, refetch: refetchServiceRequest } = useServiceRequest(id as string);
  const { data: products } = useFetchProducts(id as string);
  const { mutate: updateServiceRequest } = useServiceRequestUpdate(id, setSelectedType);
  const { mutateAsync: createInvoice, data: invoiceData } = useCreateInvoice(id);
  const { data: invoice, refetch: refetchInvoice } = useGetInvoice(data?.invoice_id as string);
  const { data: generatedReportURL, refetch } = useGenerateReport(id as string);

  useEffect(() => {
    refetchServiceRequest();
  }, [refetchServiceRequest]);

  const hasAppointment = !!data?.appointment;
  const allTodosCompleted = data?.todos?.every(todo => todo.status === 'COMPLETED');
  const completedTodo = data?.todos?.findIndex(todo => todo.status === 'COMPLETED') !== -1;
  const nextScheduledTodo = !allTodosCompleted &&
    completedTodo &&
    data?.todos[data?.todos?.findLastIndex(todo => todo.status === 'COMPLETED') + 1].status === 'SCHEDULED';
  const appointmentReschedule = !hasAppointment && completedTodo && nextScheduledTodo;
  const isDeclined = data?.appointment?.status === 'DECLINED';

  const isMakeAppointmentActive = !hasAppointment || appointmentReschedule || isDeclined;

  const [isVisibleAppointment, setVisibleAppointment] = useState<boolean>(false);
  const [isVisiblePreliminaryInvoice, setVisiblePreliminaryInvoice] = useState<boolean>(false);
  const [isVisibleInvoice, setVisibleInvoice] = useState<boolean>(false);
  const [isVisibleErrorModal, setVisibleErrorModal] = useState<boolean>(false);

  const subTotal = products?.length
    ? products?.reduce((accumulator, item) => {
      const quantity = item.quantity;
      const unitPrice = parseFloat(item.unit_price);
      return accumulator + (quantity * unitPrice);
    }, 0)
    : 0;
  const vatPercentage = products?.length ? parseFloat(products[0].vat_percentage) : 0;
  const vatSum = vatPercentage * subTotal / 100;

  const TableTitleItem: React.FC<ITableCellProps> = ({ text, align = 'center' }) => {
    return (
      <TableCell
        align={align}
      >
        {text}
      </TableCell>
    );
  };

  const TodoRow = (props: { row, index }) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [reportId, setReportId] = useState(row.report_id);
    const { data: report } = useReport(reportId as string);
    const handleOpenReport = useCallback(() => {
      setReportId(row.report_id);
      setOpen(prevState => !prevState);
    }, [row.report_id]);
    const cellStyle = {
      borderBottom: `${open ? 'none' : ''}`,
    };
    const isActualStartTime = !!row?.actual_start_time;
    const isActualEndTime = !!row?.actual_end_time;
    return (
      <React.Fragment>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '80px' }}>
          <TableCell align="left" className="status-color" style={cellStyle}>
            <img src={statusIcons[row.status]} width="24" height="24" alt=""/>
          </TableCell>
          <TableCell align="left" className="text-gray" style={cellStyle}>
            {todoTypes[row?.todo_type]}
          </TableCell>
          <TableCell component="th" scope="row" sx={{ backgroundColor: '#fff' }} style={cellStyle}>
            {(row?.status === 'IN_PROGRESS' || row?.status === 'COMPLETED') && (<Box className="person">
              <Box className="person-avatar">
                { row?.technicians[0]?.first_name[0] }
                { row?.technicians[0]?.last_name[0] }
              </Box>
              { row?.technicians[0]?.first_name }
              {' '}
              { row?.technicians[0]?.last_name }
            </Box>)}
          </TableCell>
          <TableCell align="center" className="text-gray" style={cellStyle}>
            {(row?.status === 'IN_PROGRESS' || row?.status === 'COMPLETED') &&
              DateTime.fromISO(row?.actual_start_time).toFormat('dd.LL.yyyy')}
          </TableCell>
          <TableCell align="center" className="text-gray" style={cellStyle}>
            {(row?.status === 'IN_PROGRESS' || row?.status === 'COMPLETED') &&
              (<>
                {isActualStartTime ? DateTime.fromISO(row?.actual_start_time, { setZone: true }).toFormat('HH') : '-'}:
                {isActualStartTime ? DateTime.fromISO(row?.actual_start_time, { setZone: true }).toFormat('mm') : '-'}
                {isActualEndTime ? '-' : ''}
                {isActualEndTime ? DateTime.fromISO(row?.actual_end_time, { setZone: true }).toFormat('HH') : ''}
                {isActualEndTime ? ':' : ''}
                {isActualEndTime ? DateTime.fromISO(row?.actual_end_time, { setZone: true }).toFormat('mm') : ''}
              </>
              )
            }
          </TableCell>
          <TableCell align="center" className="text-gray" style={cellStyle}>
            {isActualStartTime && !isActualEndTime ? '-' : row?.todo_duration}
          </TableCell>
          <TableCell align="center" className="text-gray" style={cellStyle}>
            {(row.report_id || row.status === 'IN_PROGRESS') && (<IconButton
              aria-label="expand row"
              size="small"
              onClick={handleOpenReport}
              disableRipple
            >
              <IconArrowDown className={`${open ? 'icon-arrow-expand' : ''}`} />
            </IconButton>)
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0, border: `${!open ? 'none' : ''}` }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className="technicians-report">
                {row?.todo_type === 'PRE_SERVICE_ANALYSIS' && (
                  <>
                    <h4>List of technicians</h4>
                    <div className="technicians-list">
                      <div className="person">
                        <Box className="person">
                          <Box className="person-avatar">
                            { row?.technicians[0]?.first_name[0] }
                            { row?.technicians[0]?.last_name[0] }
                          </Box>
                          { row?.technicians[0]?.first_name }
                          {' '}
                          { row?.technicians[0]?.last_name }
                        </Box>
                      </div>
                    </div>
                  </>
                )}
                {!!report?.reschedule_reason && (
                  <>
                    <h4>Reason For Reschedule</h4>
                    <p>{report?.reschedule_reason}</p>
                  </>
                )}
                {row?.todo_type === 'POST_SERVICE_REPORT' && (
                  <>
                    <h4>Time</h4>
                    <Box className="simple-table-container">
                      <TableContainer
                        component={Paper}
                        className="simple-table-wrapper"
                        sx={{
                          margin: '16px 0 40px',
                        }}
                      >
                        <Table
                          className="simple-table"
                          aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableTitleItem align="left" text="Service/Item" />
                              <TableTitleItem align="left" text="Time"/>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {report?.times?.map((time) => (
                              <TableRow key={time.id}>
                                <TableCell>{timeTypes[time.time_type]}</TableCell>
                                <TableCell>{time.duration.split(':')[0]}:{time.duration.split(':')[1]}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </>
                )}
                {row.report_id && (<h4>{todoTypes[row.todo_type]}</h4>)}
                <p>{report?.description}</p>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                  {report?.videos?.length > 0 &&
                      <div className="request__videos" style={{ padding: 0 }}>
                        <div className="request__videos__list">
                          <VideoPlayer videos={report.videos}/>
                        </div>
                      </div>}
                  {report?.photos?.length > 0 &&
                      <div className="request__photos" style={{ padding: 0 }}>
                        <div className="request__photos__list">
                          <SliderPhoto images={data?.photos} />
                        </div>
                      </div>}
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <main>
      <ScrollToTopOnMount />
      <Button
        variant="text"
        disableRipple
        sx={{
          font: '600 1.6rem/1.6 var(--font-main)',
          padding: '0 8px',
          margin: '0 0 24px',
          gap: '4px',
          boxShadow: 'none !important',
          backgroundColor: 'transparent',
          height: '32px',
          color: 'var(--accent)',
          display: 'flex',
          alignItems: 'center',
          transition: 'all 0.25s linear',
          '&:hover': {
            color: 'var(--accent-hover)',
            backgroundColor: 'transparent',
          },
          '&:active': {
            color: 'var(--accent-active)',
            backgroundColor: 'transparent',
          },
        }}
        onClick={() => { navigate(ROUTES.MAIN); }}
      >
        <img src={IconBack} width="24" height="24" alt="Back"/>
                    Back to Dashboard
      </Button>

      <div className="request">
        <article className="request-article">
          <section className="request__info">
            <div className="request__info__heading">
              <h4>Client request information</h4>
              <IconButton className="button--small" disableRipple>
                <img src={IconEdit} width="32" height="32" alt="edit" />
              </IconButton>
            </div>
            <div className="request__info__inner">
              <div className="request__info__client">
                <h3>
                  {data?.customer?.person_title && (PERSON_TITLE[data?.customer?.person_title] + ' ')}
                  {data?.customer?.first_name}{' '}
                  {data?.customer?.last_name}
                </h3>
                <ul className="request__info__communication">
                  <li>
                    <a href={`mailto:${data?.customer?.email}`}>
                      <img src={IconEmail} width="20" height="20"
                        alt="email" />
                      {data?.customer?.email}
                    </a>
                  </li>
                  <li>
                    <a href={`tel:${data?.contact_phone}`} className="tel">
                      <img src={IconPhone} width="20" height="20"
                        alt="phone"/>
                      {data?.contact_phone}
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src={IconChat} width="20" height="20"
                        alt="chat"/>
                        Go to chat
                    </a>
                  </li>
                </ul>

                <dl>
                  <dt>Client Status</dt>
                  <dd>
                    <div className="status-color">{data?.customer?.status}</div>
                  </dd>
                </dl>
                <dl>
                  <dt>Address</dt>
                  <dd>
                    {data?.address}{', '}
                    {data?.postcode}{' '}
                    {data?.city}
                  </dd>
                </dl>
                <dl>
                  <dt>Languages spoken</dt>
                  <dd>
                    {data?.customer?.preferred_languages.map(lang =>
                      <img key={lang} src={languages[lang]}
                        width="20" height="15" alt=""/>,
                    )}
                  </dd>
                </dl>
              </div>
              <div className="request__map">
                <div className="request__map__inner">
                  <APIProvider apiKey={import.meta.env.APP_GOOGLE_MAPS_KEY} libraries={['marker']}>
                    <ClientsMap serviceRequestData={data} />
                  </APIProvider>
                </div>
              </div>
            </div>

            <div className="request__description">
              <h4>Problem description</h4>
              <p>{data?.description}</p>
            </div>
            {data?.videos?.length > 0 &&
                <div className="request__videos">
                  <h4>Videos</h4>
                  <div className="request__videos__list">
                    <VideoPlayer videos={data?.videos}/>
                  </div>
                </div>}
            {data?.photos?.length > 0 &&
                <div className="request__photos">
                  <h4>Photos</h4>
                  <div className="request__photos__list">
                    <SliderPhoto images={data?.photos} />
                  </div>
                </div>}
          </section>

          <h4>To Do</h4>
          <section>
            <Box>
              <Box className="simple-table-container">
                <TableContainer
                  component={Paper}
                  className="simple-table-wrapper"
                  sx={{
                    margin: '16px 0 40px',
                  }}
                >
                  <Table
                    className="simple-table"
                    aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableTitleItem text="Status" />
                        <TableTitleItem text="Task"/>
                        <TableTitleItem align="left" text="Executor" />
                        <TableTitleItem text="Date" />
                        <TableTitleItem text="Period" />
                        <TableTitleItem text="Time" />
                        <TableTitleItem text="" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.todos?.map((todo) => (
                        <TodoRow key={todo.id} row={todo} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </section>
        </article>

        <aside className="request-aside">
          <div className="request-aside__details">
            <dl>
              {
                data?.status === 'NEW' && (<dt>
                  <div className="mark-status mark-status--violet">Request</div>
                </dt>)
              }
              {
                data?.status === 'IN_PROGRESS' && (<dt>
                  <div className="mark-status mark-status--yellow">In progress</div>
                </dt>)
              }
              {
                data?.status === 'COMPLETED' && (<dt>
                  <div className="mark-status mark-status--orange">Completed</div>
                </dt>)
              }
              {
                data?.status === 'PAID' && (<dt>
                  <div className="mark-status mark-status--green">Paid</div>
                </dt>)
              }
              {data?.mark && data?.status === 'IN_PROGRESS' && (
                <dd>
                  <div className="indicator indicator--yellow" />
                </dd>
              )}
              {data?.emergency && data?.status === 'NEW' && (
                <dd>
                  <div className="indicator indicator--red" />
                </dd>
              )}
            </dl>
            <dl>
              <dt>Request number</dt>
              <dd>{'#'}{data?.number}</dd>
            </dl>
            <dl>
              <dt>Date created</dt>
              <dd>{!!data && DateTime.fromISO(data.created).toLocaleString()}</dd>
            </dl>
            {products?.length > 0 &&
              (<>
                <dl>
                  <dt>Preliminary price</dt>
                  <dd>{(vatSum + subTotal).toFixed(2)}€</dd>
                </dl>
                <button
                  className="button button--text-small edit-appointments-btn"
                  onClick={() => { setVisiblePreliminaryInvoice(true); }}
                >
                  Show Preliminary invoice
                  <img className="edit-icon" src={IconBack} width="20" height="20" alt="Show Preliminary invoice"/>
                </button>
              </>
              )
            }
          </div>
          <FormControl
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'var(--gray-middle)',
                  borderRadius: '8px',
                  borderWidth: '1px !important',
                  transition: '0.3s',
                },
                '&:hover': {
                  '& fieldset': {
                    borderColor: 'var(--gray-middle)',
                  },
                },
              },
            }}
          >
            <Select
              className="select-wrapper"
              IconComponent={() => null}
              labelId="client-status-label"
              id="client-status-label-select"
              label=""
              value={data?.category ?? selectedType}
              onChange={(e) => {
                const selectedValue = e.target.value;
                updateServiceRequest({ id, selectedValue });
              }}
            >
              {TYPE_OF_WORK_LIST.map((user) => (
                <MenuItem key={user.id} value={user.value} className="select-item">{user.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {data?.appointment?.status === 'NOT_SELECTED' && (<div className="request-aside__details appointment-details">
            <h4>Appointment</h4>
            <div className="request-aside__text">Waiting for client response</div>
            <button className="button button--text-small edit-appointments-btn" onClick={() => { setVisibleAppointment(true); }}>
              Edit date and time
              <img className="edit-icon" src={IconBack} width="20" height="20" alt="Edit date and time"/>
            </button>
          </div>)}
          {data?.appointment?.status === 'DECLINED' && (<div className="request-aside__details request-aside__details_appointment-details">
            <h4>Appointment <img src={IconWarning} width="24" height="24" alt=""/></h4>
            <div className="request-aside__text">The client declined the proposed time slots. Select other date and time</div>
          </div>)}
          {appointmentReschedule && (<div className="request-aside__details request-aside__details_appointment-details">
            <h4>
              Appointment
              <img src={IconWarning} width="24" height="24" alt=""/>
            </h4>
            <div className="request-aside__text">The technician requested a rescheduling of the service delivery date
            </div>
          </div>)}
          {/* eslint-disable-next-line no-mixed-operators */}
          {(!allTodosCompleted && data?.appointment?.status === 'SELECTED' && hasAppointment) &&
            (<div className="request-aside__details appointment-details">
              <h4>Appointment</h4>
              <div className="selected-options">
                <div className="selected-date">
                  <img src={IconCalendar} width="20" height="20" alt=""/>
                  {DateTime.fromISO(data?.appointment?.option?.date).toFormat('dd.MM.yyyy')}
                </div>
                <div className="selected-time">
                  <img src={IconClock} width="20" height="20" alt=""/>
                  {DateTime.fromFormat(data?.appointment?.option?.start_time, 'HH:mm:ss').toFormat('HH:mm')}-
                  {DateTime.fromFormat(data?.appointment?.option?.end_time, 'HH:mm:ss').toFormat('HH:mm')}
                </div>
              </div>
              {data?.appointment?.option?.technicians.map(technician => {
                const { id, first_name, last_name } = technician;
                return (
                  <div key={id} className="details_person">
                    <div className="details_technic-avatar">
                      {first_name[0]}{last_name[0]}
                    </div>
                    <div className="details_option">
                      {first_name} {last_name}
                    </div>
                  </div>
                );
              })}
            </div>)
          }
          {allTodosCompleted && data?.status === 'IN_PROGRESS' &&
            (<button
              className="button button--default"
              onClick={async () => {
                if (!invoice) {
                  await createInvoice();
                }
                setVisibleInvoice(true);
              }}
            >
              <img src={IconPaper} width="20" height="20" alt="Service delivered"/>
            Service delivered
            </button>)}
          {!allTodosCompleted && (<button
            className="button button--default"
            disabled={!isMakeAppointmentActive}
            onClick={() => { setVisibleAppointment(true); }}
          >
            Make an appointment
          </button>)}
          <MakeAppointmentWidget
            isVisible={isVisibleAppointment}
            setVisible={setVisibleAppointment}
            appointment={data?.appointment}
            isRescheduling={appointmentReschedule}
          />
          <PreliminaryInvoiceWidget
            isVisible={isVisiblePreliminaryInvoice}
            setVisible={setVisiblePreliminaryInvoice}
            products={products}
          />
          <CreateInvoiceWidget
            isVisible={isVisibleInvoice}
            setVisible={setVisibleInvoice}
            data={invoiceData?.data || invoice}
          />
          <ModalWindow
            isVisible={isVisibleErrorModal}
            setIsVisible={setVisibleErrorModal}
            title="Error"
            subtitle="We are sorry!"
            description="Try generating the report again later"
            secondBtnText="Okay"
            secondBtnAction={() => {
              setVisibleErrorModal(false);
            }}
          />
          {(data?.status === 'COMPLETED' || data?.status === 'PAID') && (<button className="button button--border" onClick={async () => {
            await refetch();
            if (generatedReportURL?.url) {
              window.open(generatedReportURL?.url, '_blank');
            } else {
              setVisibleErrorModal(true);
            }
          }}>
            <img src={IconPDF} width="20" height="20" alt="Generate a report"/>
            Generate a report
          </button>)}
          {/* <button className="button button--border"> */}
          {/*  <img src={IconPDF} width="20" height="20" alt="Generate a report"/> */}
          {/*  Generate a report */}
          {/* </button> */}
          <button className="button button--text">Cancel request</button>
        </aside>
      </div>
    </main>
  );
};

export default DetailServiceRequestWidget;
