import React, { useState } from 'react';
import classNames from 'classnames/bind';
import {
  Box,
  type SxProps,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import phoneIcon from '@shared/assets/Icons/icon_phone.svg';
import chatIcon from '@shared/assets/Icons/icon_chat.svg';

import { useClientsList } from '@features/ClientsListTable/model/useClientsList';

import { type IClient, type IClientsList } from '@features/ClientsListTable/config/interfaces';

import { useServiceProviderProfile } from '@widgets/HeaderWidget/model/useServiceProviderProfile';

import { getDistanceLocation } from '@shared/lib/utils/getDistanceLocation';

import { Pagination } from './ui';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ITableCellProps {
  text: string
  align?: 'left' | 'right' | 'center' | 'inherit' | 'justify' | undefined
  sx?: SxProps | undefined
}

const ClientsListTable: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const { data: clientsList } = useClientsList(page, pageSize);
  const { data } = useServiceProviderProfile();
  const SPLocation =
      {
        lat: data?.address?.location?.latitude ?? 0,
        lng: data?.address?.location?.longitude ?? 0,
      };

  const initialTable: IClientsList = {
    count: 0,
    next: null,
    previous: null,
    results: [],
  };

  const TableTitleItem: React.FC<ITableCellProps> = ({ text, align = 'center' }) => {
    return (
      <TableCell
        align={align}
      >
        {text}
      </TableCell>
    );
  };

  return (
    <Box>
      <Box className={cx('simple-table-container')}>
        <TableContainer
          component={Paper}
          className={cx('simple-table-wrapper')}
        >
          <Table
            className={cx('simple-table')}
            aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableTitleItem align="left" text="Client Name" />
                <TableTitleItem text="Status" />
                <TableTitleItem text="Location" />
                <TableTitleItem text="Contact" />
                <TableTitleItem text="Active requests" />
                <TableTitleItem text="Paid requests" />
                <TableTitleItem text="Total sum, €" />
              </TableRow>
            </TableHead>
            <TableBody>
              {clientsList?.results.map((row: IClient, index: number) => {
                const CustomerLocation =
                    {
                      lat: row?.address?.location?.latitude ?? 0,
                      lng: row?.address?.location?.longitude ?? 0,
                    };
                const distance = getDistanceLocation(SPLocation, CustomerLocation);
                return (
                  <TableRow
                    key={`row.name-${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ backgroundColor: '#fff' }}
                    >
                      <Box className={cx('person')}>
                        <Box className={cx('person-avatar')}>
                          { row?.first_name[0] }
                          { row?.last_name[0] }
                        </Box>
                        { row?.first_name }
                        {' '}
                        { row?.last_name }
                      </Box>
                    </TableCell>
                    <TableCell align="center" className={cx('status-color')}>{row.status}</TableCell>
                    <TableCell align="center" className={cx('text-gray')}>{distance}{' km'}</TableCell>
                    <TableCell align="center">
                      <a href={`tel:${row.phone}`}>
                        <img src={phoneIcon} alt="phone" />
                      </a>
                      <img src={chatIcon} alt="chat" />
                    </TableCell>
                    <TableCell align="center" className={cx('text-gray')}>{row.active_requests_quantity}</TableCell>
                    <TableCell align="center" className={cx('text-gray')}>{row.paid_requests_quantity}</TableCell>
                    <TableCell align="center" className={cx('text-gray')}>{row.paid_requests_sum}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          tableData={clientsList ?? initialTable}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Box>
    </Box>
  );
};

export default ClientsListTable;
