export const HeaderNavItems = [
  {
    title: 'Dashboard',
    path: '/',
  },
  {
    title: 'Clients',
    path: '/clients',
  },
  {
    title: 'Team',
    path: '/team',
  },
  {
    title: 'Message',
    path: '/message',
  },
];
