// @ts-nocheck
import React from 'react';
import './styles.scss';
import { Controller, useWatch } from 'react-hook-form';
import { Autocomplete, Chip, FormControl, TextField, Avatar } from '@mui/material';
import IconClose from '@shared/assets/Icons/icon_close_small.svg?react';
import { useFetchTechnicians } from '@widgets/MakeAppointmentWidget/model/useFetchTechnicians';

const technicianStatus = {
  BUSY: { backgroundColor: '#FFF6D3', color: '#FAC814' },
  AVAILABLE: { backgroundColor: '#E7F7EF', color: '#0CAF60' },
  UNAVAILABLE: { backgroundColor: '#FFEDEC', color: '#E03137' },
};

const MultiSelect: React.FC = ({ index, control }) => {
  const date = useWatch({
    control,
    name: `options[${index}].date`,
  });
  const startTime = useWatch({
    control,
    name: `options[${index}].start_time`,
  });
  const endTime = useWatch({
    control,
    name: `options[${index}].end_time`,
  });

  const isDisableSelect = !(!!date && !!startTime && !!endTime);

  const { data } = useFetchTechnicians(date, startTime, endTime);

  const options = data?.results?.map(tech => {
    return {
      id: tech.id,
      label: tech.first_name + ' ' + tech.last_name,
      techStatus: tech.employment_status,
      first_name: tech.first_name,
      last_name: tech.last_name,
    };
  });
  return (
    <FormControl
      sx={{
        '& .MuiOutlinedInput-root': {
          minHeight: '48px',
          maxHeight: '96px',
          gap: '4px',
          '& fieldset': {
            borderColor: 'var(--gray-middle)!important',
            borderRadius: '8px',
            borderWidth: '1px !important',
            transition: '0.3s',
          },
          '&:hover': {
            '& fieldset': {
              borderColor: 'var(--gray-middle)',
            },
          },
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.MuiAutocomplete-inputRoot': {
          padding: 0,
          height: '48px',
        },
        '& .MuiAutocomplete-root': {
          '.MuiAutocomplete-input': {
            height: '100%',
            padding: '0 6px!important',
            border: 'none',
          },
          '.MuiAutocomplete-clearIndicator': {
            marginRight: '4px',
          },
        },
        '& .MuiAutocomplete-listbox': {
          fontSize: '1.4rem!important',
        },
        '& .MuiAutocomplete-tag': {
          margin: 0,
          marginRight: '4px',
          borderRadius: '8px',
          backgroundColor: '#F2F6FF',
          fontSize: '12px',
          fontWeight: 600,
        },
        '& .MuiAutocomplete-option': {
          '.Mui-focused': {
            backgroundColor: 'white',
          },
        },
        '& .MuiButtonBase-root.MuiChip-root.MuiAutocomplete-tag': {
          margin: 0,
          marginRight: '4px',
        },
        '& .MuiAvatar-root.MuiAvatar-circular.MuiChip-avatar': {
          backgroundColor: '#0E66FF40',
          fontSize: '9px',
          fontWeight: 700,
          width: '20px',
          height: '20px',
          marginLeft: '8px',
        },
      }}
    >
      <Controller
        name={`options[${index}].technicians_ids`}
        control={control}
        defaultValue={options || []}
        render={({ field }) => (
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={options ?? []}
            noOptionsText="No employees"
            disableCloseOnSelect
            disabled={isDisableSelect}
            getOptionLabel={(option) => option.label}
            defaultValue={options || []}
            value={field.value || []}
            onChange={(event, value) => {
              field.onChange(value);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, { selected }) => {
              const isDisabled = option.techStatus === 'UNAVAILABLE';
              return (
                <li {...props} className="multiselect_option" style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}>
                  <div>
                    <input
                      name={option.label}
                      disabled={isDisabled}
                      type="checkbox" checked={selected} value=""
                    />
                    <label htmlFor={option.label} style={{ fontSize: '14px', fontWeight: 600 }}>
                      <div className="multiselect_technic-avatar">
                        <div>{option.first_name[0] + option.last_name[0]}</div>
                      </div>
                      {option.label}
                    </label>
                  </div>
                  <Chip label={option.techStatus} sx={{
                    width: '100px',
                    backgroundColor: `${technicianStatus[option.techStatus].backgroundColor}`,
                    color: `${technicianStatus[option.techStatus].color}`,
                    fontSize: '10px',
                    fontWeight: 700,
                  }}/>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select employee" />
            )}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    avatar={<Avatar>{option.first_name[0] + option.last_name[0]}</Avatar>}
                    label={`${option.first_name} ${option.last_name}`}
                    key={key}
                    deleteIcon={<IconClose />}
                    {...tagProps}
                  />
                );
              })
            }
          />
        )}
      />
    </FormControl>
  );
};

export default MultiSelect;
