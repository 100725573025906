// @ts-nocheck
import React from 'react';
import './styles.scss';
import { DateTime } from 'luxon';
import { type IServiceRequestsList } from '@pages/DashboardPage/config/interfaces';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { Box, type SxProps } from '@mui/material';
import { REQUEST_CATEGORY } from '@widgets/DashboardListViewWidget/config/constants';
import { NavLink } from 'react-router-dom';
import { PERSON_TITLE } from '@widgets/DetailServiceRequestWidget/config/constants';
import { useServiceProviderProfile } from '@widgets/HeaderWidget/model/useServiceProviderProfile';
import { getDistanceLocation } from '@shared/lib/utils/getDistanceLocation';

interface ITableCellProps {
    text: string
    align?: 'left' | 'right' | 'center' | 'inherit' | 'justify' | undefined
    sx?: SxProps | undefined
}

interface IDashboardListViewProps {
    serviceRequestList: IServiceRequestsList[] | undefined
}

const DashboardListViewWidget: React.FC<IDashboardListViewProps> = ({ serviceRequestList }) => {
  const { data } = useServiceProviderProfile();
  const SPLocation =
      {
        lat: data?.address?.location?.latitude ?? 0,
        lng: data?.address?.location?.longitude ?? 0,
      };
  const TableTitleItem: React.FC<ITableCellProps> = ({ text, align = 'center' }) => {
    return (
      <TableCell
        align={align}
      >
        {text}
      </TableCell>
    );
  };

  return (
    <Box className="tab-content">
      <Box className="simple-table-container">
        <TableContainer
          component={Paper}
          className="simple-table-wrapper"
        >
          <Table
            className="simple-table"
            aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableTitleItem align="left" text="Request ID" />
                <TableTitleItem align="left" text="Client Name" />
                <TableTitleItem text="Status" />
                <TableTitleItem text="Type of work" />
                <TableTitleItem text="Request Status" />
                <TableTitleItem text="How far" />
                <TableTitleItem text="Date" />
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceRequestList?.map((serviceRequest) => {
                const { id, created, number, customer, category, emergency, status, mark } = serviceRequest;
                const ServiceRequestLocation =
                    {
                      lat: serviceRequest?.customer?.location?.latitude ?? 0,
                      lng: serviceRequest?.customer?.location?.longitude ?? 0,
                    };
                const distance = getDistanceLocation(SPLocation, ServiceRequestLocation);
                const diff = DateTime.fromISO(created).diff(DateTime.now(), ['days']);
                const createdDate = Math.abs(diff.toObject().days as number) > 4
                  ? DateTime.fromISO(created).toLocaleString()
                  : DateTime.now().setLocale('en').plus(diff).toRelativeCalendar();
                return (
                  <TableRow
                    component={NavLink}
                    key={id}
                    to={`/service-request/detail/${id}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left" className="text-gray dashboard-list">
                      <div className="client-id">
                        {emergency && status === 'NEW' &&
                          (<div className="indicator indicator--red"/>)
                        }
                        {mark &&
                          (<div className="indicator indicator--yellow"/>)
                        }
                        {'#'}{number}
                      </div>
                    </TableCell>
                    <TableCell
                      scope="row"
                    >
                      <Box className="person">
                        <Box className="person-avatar">
                          {customer?.first_name[0]}
                          {customer?.last_name[0]}
                        </Box>
                        {customer?.person_title && (PERSON_TITLE[customer.person_title] + ' ')}
                        {customer?.first_name}
                        {' '}
                        {customer?.last_name}
                      </Box>
                    </TableCell>
                    <TableCell align="center" className="status-color">
                      {customer?.status}
                    </TableCell>
                    <TableCell align="center">
                      {REQUEST_CATEGORY[category]}
                    </TableCell>
                    <TableCell align="left">
                      <div className={`line-indicator line-indicator--${status === 'NEW' ? 'request' : 'inprogress'}`}>
                        {status === 'NEW' ? 'Request' : 'In progress'}
                      </div>
                    </TableCell>
                    <TableCell align="center" className="text-gray">
                      {distance}{' km'}
                    </TableCell>
                    <TableCell align="center">
                      {createdDate}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DashboardListViewWidget;
