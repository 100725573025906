// @ts-nocheck
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Drawer } from '@mui/material';
import IconClose from '@shared/assets/Icons/icon_close.svg';
import Invoice from '@widgets/MakeAppointmentWidget/ui/Invoice';
import { type IProduct } from '@widgets/MakeAppointmentWidget/config/interfaces';
import { useSendInvoice } from '@widgets/CreateInvoiceWidget/model/useSendInvoice';
import AddInvoice from '@widgets/MakeAppointmentWidget/ui/AddInvoice';
import IconPlus from '@shared/assets/Icons/icon_plus_accent.svg';
import { useFetchProducts } from '@widgets/MakeAppointmentWidget/model/useFetchProducts';
import { useParams } from 'react-router-dom';
import IconEdit from '@shared/assets/Icons/icon_edit.svg';
import { useGetInvoice } from '@widgets/CreateInvoiceWidget/model/useGetInvoice';
import { useServiceRequest } from '@widgets/DetailServiceRequestWidget/model/useServiceRequest';
import { ModalWindow } from '@widgets/CreateServiceRequestWidget/ui';

const timeTypes = {
  COMMUTING_TIME: 'Commuting time',
  PRE_SERVICE_ANALYSIS: 'Pre-service analysis',
  SERVICE: 'Service in-progress',
  POST_SERVICE_REPORT: 'Post-service',
  TOTAL_TIME: 'Total time',
} as Record<string, string>;

interface IInvoiceWidgetProps {
  isVisible: boolean
  setVisible: (boolean) => void
  products?: IProduct[]
  data?: unknown
}

const CreateInvoiceWidget: React.FC<IInvoiceWidgetProps> = ({ isVisible, setVisible, data }) => {
  const [currentProduct, setCurrentProduct] = useState<IProduct>(null);
  const [currentTodo, setCurrentTodo] = useState(null);
  const [isInvoiceVisible, setInvoiceVisible] = useState<boolean>(false);
  const [isSuccessSendVisible, setSuccessSendVisible] = useState<boolean>(false);

  const onSuccessSendInvoice = () => {
    setVisible(false);
    setSuccessSendVisible(true);
  };

  const { id } = useParams();
  const { data: serviceRequest } = useServiceRequest(id as string);
  const { mutateAsync: sendInvoice } = useSendInvoice(id, onSuccessSendInvoice);
  const { data: products, refetch: refetchProducts } = useFetchProducts(id as string);
  const { data: invoice, refetch: refetchInvoice } = useGetInvoice(serviceRequest?.invoice_id as string);

  useEffect(() => {
    refetchProducts();
  }, [refetchInvoice, refetchProducts, serviceRequest]);

  const handleSendInvoice = async (): Promise<void> => {
    if (data?.id) {
      await sendInvoice(data?.id);
      setVisible(false);
    }
  };

  return (
    <>
      <Drawer
        open={isVisible}
        onClose={() => {
          setVisible(false);
        }}
        anchor="right"
      >
        <button
          className="button button--small button--drawer"
          style={{ position: 'absolute', zIndex: 15 }}
          onClick={() => {
            setVisible(false);
          }}>
          <img src={IconClose} width="32" height="32" alt="close"/>
        </button>
        <div className="preliminary-invoice_container">
          <h2 style={{ marginBottom: '24px' }}>Request Payment</h2>
          {invoice?.records?.length > 0 && (
            <div className="preliminary-invoice">
              <h4 style={{ marginBottom: '8px' }}>Service summary</h4>
              <div className="product-items">
                <div className="products_header">
                  <div className="products_header-service">Service/Item</div>
                  <div className="products_header-qty">Qty</div>
                  <div className="products_header-price">Price</div>
                  <div className="products_header-empty" />
                </div>
                {invoice?.records.map((record) => <div key={record.id} className="product-item">
                  <div className="product-item_service">{timeTypes[record.service_type]}</div>
                  <div className="products-item_-qty">{record.duration}</div>
                  <div className="products-item_price">
                    <div className="products-item_price-main">{record.price}€</div>
                    <div className="products-item_price-detail">{record.price}€/{record.price}</div>
                  </div>
                  <div className="products-item_edit-invoice">
                    <button type="button" className="button button--small product-item_edit-btn">
                      <img src={IconEdit} onClick={() => { setCurrentTodo(record); setInvoiceVisible(true); }} width="32" height="32" alt="edit"/>
                    </button>
                  </div>
                </div>)}
              </div>
            </div>
          )}
          {products?.length > 0 && (
            <div className="preliminary-invoice" style={{ marginTop: '24px', marginBottom: '56px' }}>
              <h4 style={{ marginBottom: '8px' }}>Service & Items</h4>
              <div className="product-items">
                <div className="products_header">
                  <div className="products_header-service">Service/Item</div>
                  <div className="products_header-qty">Qty</div>
                  <div className="products_header-price">Price</div>
                  <div className="products_header-empty" />
                </div>
                {products.map((product, index) => <Invoice
                  setVisibleForm={setInvoiceVisible}
                  product={product}
                  setProduct={setCurrentProduct}
                  key={index} />)}
              </div>
            </div>
          )}
          <div className="form__row">
            <button
              className="button button--text-small"
              style={{ paddingLeft: 0 }}
              type="button"
              onClick={() => { setCurrentProduct(null); setInvoiceVisible(true); }}
            >
              <img src={IconPlus} width="20" height="20" alt="Add a time period"/>
            Add an invoice item
            </button>
          </div>
          <div className="product-items_total" style={{ marginBottom: '24px' }}>
            <div className="product-items_total-subtotal">
              <span>Subtotal</span>
              <span>{invoice?.subtotal}€</span>
            </div>
            <div className="product-items_total-vat">
              <span>VAT {invoice?.vat_rate}%</span>
              <span>{invoice?.vat_amount}€</span>
            </div>
            <div className="product-items_total-totalsum">
              <span>Total sum</span>
              <span>{invoice?.total}€</span>
            </div>
          </div>
          <div className="buttons__group" style={{ padding: '32px 0' }}>
            <button
              type="button"
              className="button button--border"
              onClick={() => { setVisible(false); }}
            >
            Cancel
            </button>
            <button
              className="button button--default"
              type="submit"
              onClick={handleSendInvoice}
            >
            Request Payment
            </button>
          </div>
        </div>
        <AddInvoice
          isVisible={isInvoiceVisible}
          setVisible={setInvoiceVisible}
          product={currentProduct}
          todo={currentTodo}
          setTodo={setCurrentTodo}
          setCurrentProduct={setCurrentProduct}
        />
      </Drawer>
      <ModalWindow
        isVisible={isSuccessSendVisible}
        setIsVisible={setSuccessSendVisible}
        title="Payment requested"
        subtitle="Thank you!"
        description="You have requested a payment. Wait for payment from the client"
        secondBtnText="Okay"
        secondBtnAction={() => {
          setSuccessSendVisible(false);
        }}
      />
    </>
  );
};

export default CreateInvoiceWidget;
