import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import TechnicianProfileWidget from '@widgets/TechnicianProfileWidget';
import {
  Box,
  type SxProps,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import phoneIcon from '@shared/assets/Icons/icon_phone.svg';
import chatIcon from '@shared/assets/Icons/icon_chat.svg';

import { useTechniciansList } from '@features/TechniciansListTable/model/useTechniciansList';

import { type ITechnician, type ITechniciansList } from '@features/TechniciansListTable/config/interfaces';

import { useTechnicianProfile } from '@widgets/TechnicianProfileWidget/model/useTechnicianProfile';

import { Pagination } from './ui';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ITableCellProps {
  text: string
  align?: 'left' | 'right' | 'center' | 'inherit' | 'justify' | undefined
  sx?: SxProps | undefined
}

const TechniciansListTable: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const { data: techniciansList } = useTechniciansList(page, pageSize);

  const [profileId, setProfileId] = useState<string>('');
  const [isVisibleProfile, setVisibleProfile] = useState<boolean>(false);

  const initialTable: ITechniciansList = {
    count: 0,
    next: null,
    previous: null,
    results: [],
  };

  const TableTitleItem: React.FC<ITableCellProps> = ({ text, align = 'center' }) => {
    return (
      <TableCell
        align={align}
      >
        {text}
      </TableCell>
    );
  };

  return (
    <Box>
      <Box className={cx('simple-table-container')}>
        {!!profileId &&
          <TechnicianProfileWidget
            isVisible={isVisibleProfile}
            setVisible={setVisibleProfile}
            profileId={profileId}
          />
        }
        <TableContainer
          component={Paper}
          className={cx('simple-table-wrapper')}
        >
          <Table
            className={cx('simple-table')}
            aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableTitleItem align="left" text="Technician Name" />
                <TableTitleItem text="Contact" />
                <TableTitleItem text="Active tasks" />
                <TableTitleItem text="Completed tasks" />
              </TableRow>
            </TableHead>
            <TableBody>
              {techniciansList?.results.map((row: ITechnician, index: number) => (
                <TableRow
                  key={`row.name-${index}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ backgroundColor: '#fff' }}
                  >
                    <Box className={cx('person')}
                      onClick={() => {
                        setVisibleProfile(true);
                        setProfileId(row.id);
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Box className={cx('person-avatar')}>
                        { row?.first_name[0] }
                        { row?.last_name[0] }
                      </Box>
                      { row?.first_name }
                      {' '}
                      { row?.last_name }
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <a href={`tel:${row.phone}`}>
                      <img src={phoneIcon} alt="phone" />
                    </a>
                    <img src={chatIcon} alt="chat" />
                  </TableCell>
                  <TableCell align="center" className={cx('text-gray')}>{row?.active_tasks_quantity}</TableCell>
                  <TableCell align="center" className={cx('text-gray')}>{row?.completed_tasks_quantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          tableData={techniciansList ?? initialTable}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Box>
    </Box>
  );
};

export default TechniciansListTable;
