import React from 'react';
import ReactDOM from 'react-dom/client';
import Providers from '@app/providers';
import '@shared/theme/font/fontPublicSans.css';
import '@shared/theme/style/index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Providers />
  </React.StrictMode>,
);
