// @ts-nocheck
import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IGenerateReport } from '@widgets/DetailServiceRequestWidget/config/interfaces';

export const useGenerateReport = (id: string):
  UseQueryResult<IGenerateReport, unknown> => {
  const generateReport = async (id: string): AxiosPromise<IGenerateReport> => {
    // eslint-disable-next-line no-useless-catch
    const response = await apiClient.get(`provider/service-requests/${id}/pdf-report/`, {
      withCredentials: true,
    });
    return response.data;
  };

  const queryOptions = {
    queryKey: ['generate-report', id],
    queryFn: async () => await generateReport(id),
    onSuccess: (data: AxiosResponse<IGenerateReport>) => {
      // eslint-disable-next-line no-console
      console.log(data);
    },
    onError: (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
    enabled: false,
  };

  return useQuery(queryOptions);
};
