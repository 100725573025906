// @ts-nocheck
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import classNames from 'classnames/bind';
import { Drawer } from '@mui/material';

import plusIcon from '@shared/assets/Icons/icon_plus_small.svg';
import closeIcon from '@shared/assets/Icons/icon_close.svg';

import { ROUTES } from '@shared/config/routes/constants';

import { useCreateServiceRequestDraft } from './model/useCreateServiceRequestDraft';
import { useCreateServiceRequest } from './model/useCreateServiceRequest';
import { useCreateServiceRequestConfirm } from './model/useCreateServiceRequestConfirm';

import { ActionButtons, RequestForm, ModalWindow } from './ui';
import {
  type IPhoto,
  type IServiceRequest, type IServiceRequestClient,
  type IServiceRequestDataForm,
  type IServiceRequestDraft,
  type IVideo,
} from './config/interfaces';

import { MODAL_WINDOWS_TYPES } from './config/constants';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface ICreateServiceRequestWidgetProps {
  fromHeader?: boolean
}

const CreateServiceRequestWidget: React.FC<ICreateServiceRequestWidgetProps> = ({ fromHeader }) => {
  const [isVisibleDrawer, setIsVisibleDrawer] = useState<boolean>(false);
  const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState<boolean>(false);
  const [isVisibleCancelModal, setIsVisibleCancelModal] = useState<boolean>(false);
  const [isVisibleDoneModal, setIsVisibleDoneModal] = useState<boolean>(false);
  const [longitude, setLongitude] = useState<number>(0);
  const [latitude, setLatitude] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<IServiceRequestClient | null>(null);

  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      userName: '',
      phone: '',
      address: '',
      city: '',
      postcode: '',
      serviceCategory: '',
      emergency: false,
      requestDescription: '',
      videos: [],
      photos: [],
      additionalNotes: '',
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isValid },
  } = methods;

  const watchPhotos: IPhoto[] = watch().photos;
  const watchVideos: IVideo[] = watch().videos;

  const draft: IServiceRequestDraft = {
    customer_id: null,
  };

  const onDraftSuccess = (): void => {
    setIsVisibleDrawer(true);
  };

  const onSuccess = (): void => {
    setIsVisibleConfirmModal(true);
  };

  const onConfirmSuccess = (): void => {
    setIsVisibleConfirmModal(false);
    setIsVisibleDrawer(false);
    setIsVisibleDoneModal(true);
    reset();
  };

  const { mutate: createServiceRequestDraft, data } = useCreateServiceRequestDraft({ onDraftSuccess });
  const draftData = data?.data;

  const { id: serviceRequestId, number, created: date } = draftData ?? {};

  const { mutate: createServiceRequest } = useCreateServiceRequest({ onSuccess, serviceRequestId });

  const { mutate: createServiceRequestConfirm } =
    useCreateServiceRequestConfirm({ onConfirmSuccess, serviceRequestId });

  const onSubmit = (data: IServiceRequestDataForm): void => {
    const request: IServiceRequest = {
      customer_id: data.userName,
      contact_phone: data.phone,
      description: data.requestDescription,
      category: data.serviceCategory,
      emergency: data.emergency,
      address: data.address,
      city: data.city,
      postcode: data.postcode,
      location: {
        longitude: selectedUser ? selectedUser?.address?.location?.longitude : longitude,
        latitude: selectedUser ? selectedUser?.address?.location?.latitude : latitude,
      },
      notes: data.additionalNotes,
    };
    createServiceRequest(request);
  };

  return (
    <>
      {fromHeader && (
        <a
          href="#"
          onClick={() => { createServiceRequestDraft(draft); }}
        >
          Request
        </a>
      )}
      {!fromHeader && (
        <button
          className={cx('button', 'button--default', 'button--add')}
          type="submit"
          onClick={() => {
            createServiceRequestDraft(draft);
          }}
        >
          <img src={plusIcon} width="20" height="20" alt="Service request"/>
          Service request
        </button>
      )}
      <Drawer
        open={isVisibleDrawer}
        onClose={() => {
          setIsVisibleCancelModal(true);
        }}
        anchor="right"
      >
        <div className={cx('drawer')}>
          <button
            className={cx('button', 'button--small', 'button--drawer')}
            onClick={(): void => { setIsVisibleCancelModal(true); }}
          >
            <img src={closeIcon} width="32" height="32" alt="close"/>
          </button>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <RequestForm
                id={number}
                date={date}
                photos={watchPhotos}
                setPhotos={(value) => { setValue('photos', value as never[]); }}
                videos={watchVideos}
                setVideos={(value) => { setValue('videos', value as never[]); }}
                setLongitude={setLongitude}
                setLatitude={setLatitude}
                serviceRequestId={serviceRequestId}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
              <ActionButtons onClose={setIsVisibleCancelModal} isValid={isValid} />
            </form>
          </FormProvider>
        </div>
      </Drawer>
      <ModalWindow
        isVisible={isVisibleConfirmModal}
        setIsVisible={setIsVisibleConfirmModal}
        title={MODAL_WINDOWS_TYPES.CONFIRM.title}
        description={MODAL_WINDOWS_TYPES.CONFIRM.description}
        firstBtnText={MODAL_WINDOWS_TYPES.CONFIRM.firstBtnText}
        secondBtnText={MODAL_WINDOWS_TYPES.CONFIRM.secondBtnText}
        firstBtnAction={() => { setIsVisibleConfirmModal(false); }}
        secondBtnAction={createServiceRequestConfirm}
      />
      <ModalWindow
        isVisible={isVisibleDoneModal}
        setIsVisible={setIsVisibleDoneModal}
        title={MODAL_WINDOWS_TYPES.DONE.title}
        subtitle={MODAL_WINDOWS_TYPES.DONE.subtitle}
        description={MODAL_WINDOWS_TYPES.DONE.description}
        firstBtnText={MODAL_WINDOWS_TYPES.DONE.firstBtnText}
        secondBtnText={MODAL_WINDOWS_TYPES.DONE.secondBtnText}
        firstBtnAction={() => {
          setIsVisibleDoneModal(false);
          navigate(`/service-request/detail/${serviceRequestId}`);
        }}
        secondBtnAction={() => {
          setIsVisibleDoneModal(false);
          navigate(ROUTES.MAIN);
        }}
      />
      <ModalWindow
        isVisible={isVisibleCancelModal}
        setIsVisible={setIsVisibleCancelModal}
        title={MODAL_WINDOWS_TYPES.CANCEL.title}
        subtitle={MODAL_WINDOWS_TYPES.CANCEL.subtitle}
        description={MODAL_WINDOWS_TYPES.CANCEL.description}
        firstBtnText={MODAL_WINDOWS_TYPES.CANCEL.firstBtnText}
        secondBtnText={MODAL_WINDOWS_TYPES.CANCEL.secondBtnText}
        firstBtnAction={() => { setIsVisibleCancelModal(false); }}
        secondBtnAction={() => {
          setIsVisibleCancelModal(false);
          setIsVisibleDrawer(false);
          reset();
        }}
      />
    </>
  );
};

export default CreateServiceRequestWidget;
