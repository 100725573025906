export const REQUEST_STATUS = {
  NEW: 'New',
  DRAFT: 'Draft',
};

export const REQUEST_CATEGORY = {
  OTHER: 'Other',
  REPAIR: 'Repair',
  PROJECT: 'Project',
  MAINTENANCE: 'Maintenance',
};
