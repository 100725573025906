export const VAT = 17;

export const MAX_LIMIT_TIME_PERIOD = 3;

export const UNITS = [
  {
    id: 1,
    label: 'Pieces',
    shortLabel: 'pcs',
    value: 'PIECES',
  },
  {
    id: 2,
    label: 'Hours',
    shortLabel: 'hrs',
    value: 'HOURS',
  },
  {
    id: 3,
    label: 'Meters',
    shortLabel: 'm',
    value: 'METERS',
  },
  {
    id: 4,
    label: 'Cubic meters',
    shortLabel: 'cub m',
    value: 'CUBIC_METERS',
  },
  {
    id: 5,
    label: 'Square meters',
    shortLabel: 'sq m',
    value: 'SQUARE_METERS',
  },
  {
    id: 6,
    label: 'Liters',
    shortLabel: 'l',
    value: 'LITERS',
  },
  {
    id: 7,
    label: 'Kilograms',
    shortLabel: 'kg',
    value: 'KILOGRAMS',
  },
  {
    id: 8,
    label: 'Tons',
    shortLabel: 'ton',
    value: 'TONS',
  },
];
