import React from 'react';
import plusIcon from '@shared/assets/Icons/icon_plus_small.svg';
import { Button, Icon } from '@mui/material';

const AddNewClient: React.FC = () => {
  const PlusIcon = (
    <Icon>
      <img src={plusIcon} alt="Add new"/>
    </Icon>
  );

  return (
    <Button
      variant="contained"
      startIcon={PlusIcon}
      disableRipple
      sx={{
        font: '600 1.6rem/1.6 var(--font-main)',
        padding: '0 24px',
        margin: '0 0 0 auto',
        borderRadius: '8px',
        boxShadow: 'none !important',
        height: '48px',
        color: 'var(--white)',
        backgroundColor: 'var(--accent)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.25s linear',
        '&:hover': {
          backgroundColor: 'var(--accent-hover)',
        },
        '&:active': {
          backgroundColor: 'var(--accent-active)',
        },
      }}
    >
      Add new
    </Button>
  );
};

export default AddNewClient;
