import { type IRequestStatus, type ITypeOfWork } from './interfaces';

export const REQUEST_STATUS_LIST: IRequestStatus[] = [
  {
    id: '0',
    value: 'empty',
    label: 'Request status',
  },
  {
    id: '3',
    value: 'CANCELED',
    label: 'Canceled',
  },
  {
    id: '5',
    value: 'PAID',
    label: 'Paid',
  },
  {
    id: '6',
    value: 'IN_PROGRESS',
    label: 'In progress',
  },
  {
    id: '7',
    value: 'COMPLETED',
    label: 'Completed',
  },
];

export const TYPE_OF_WORK_LIST: ITypeOfWork[] = [
  {
    id: '0',
    value: 'empty',
    label: 'Type of work',
  },
  {
    id: '1',
    value: 'MAINTENANCE',
    label: 'Maintenance',
  },
  {
    id: '2',
    value: 'REPAIR',
    label: 'Repair',
  },
  {
    id: '3',
    value: 'PROJECT',
    label: 'Project',
  },
  {
    id: '4',
    value: 'OTHER',
    label: 'Other',
  },
];
