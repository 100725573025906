import { useMutation, type UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { type IMakeAppointmentsRequest } from '@widgets/MakeAppointmentWidget/config/interfaces';

export const useMakeAppointments = (id: string, onSubmitSuccess: () => void): UseMutationResult<unknown, unknown, IMakeAppointmentsRequest, unknown> => {
  const makeAppointment = async (appointmentOptions: IMakeAppointmentsRequest): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      'provider/appointments/',
      appointmentOptions,
    );
  };
  const queryClient = useQueryClient();
  const mutationOptions: {
    onError: (error: unknown) => void
    mutationFn: (payload: IMakeAppointmentsRequest) => AxiosPromise<AxiosResponse>
    onSuccess: (data: unknown) => void
  } = {
    mutationFn: makeAppointment,
    onSuccess: (data) => {
      onSubmitSuccess();
      void queryClient.invalidateQueries({ queryKey: ['service-request', id] });
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  };

  return useMutation(mutationOptions);
};
