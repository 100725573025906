import React from 'react';

import classNames from 'classnames/bind';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IActionButtonsProps {
  onClose: (arg: boolean) => void
  isValid: boolean
}

const ActionButtons: React.FC<IActionButtonsProps> = ({ onClose, isValid }) => {
  return (
    <div className={cx('action-buttons')}>
      <button
        type="button"
        className={cx('button', 'button--border')}
        onClick={() => {
          onClose(true);
        }}
      >
        Cancel
      </button>
      <button className={cx('button', 'button--default')} disabled={!isValid}>
        Create
      </button>
    </div>
  );
};

export default ActionButtons;
