// @ts-nocheck
import React from 'react';
import './styles.scss';
import { DateTime } from 'luxon';
import { type IServiceRequestsList } from '@pages/DashboardPage/config/interfaces';
import { NavLink } from 'react-router-dom';
import { PERSON_TITLE } from '@widgets/DetailServiceRequestWidget/config/constants';
import { getDistanceLocation } from '@shared/lib/utils/getDistanceLocation';
import { useServiceProviderProfile } from '@widgets/HeaderWidget/model/useServiceProviderProfile';

interface IDashboardGridViewProps {
    serviceRequestList: IServiceRequestsList[] | undefined
}

const DashboardGridViewWidget: React.FC<IDashboardGridViewProps> = ({ serviceRequestList }) => {
  const { data } = useServiceProviderProfile();
  const SPLocation =
      {
        lat: data?.address?.location?.latitude ?? 0,
        lng: data?.address?.location?.longitude ?? 0,
      };
  return (
    <div className="tab-content">
      <div className="dashboard">
        <div className="dashboard__section dashboard__section--requests">
          <div className="dashboard__section__header">
            Requests
            <span className="mark mark--violet">
              {serviceRequestList?.filter(serviceRequest => serviceRequest.status !== 'CANCELLED' && serviceRequest.status === 'NEW').length}
            </span>
          </div>
          {serviceRequestList?.filter(serviceRequest => serviceRequest.status !== 'CANCELLED' && serviceRequest.status === 'NEW')
            .map(serviceRequest => {
              const { id, customer, number, created, category, emergency } = serviceRequest;
              const ServiceRequestLocation =
                {
                  lat: serviceRequest?.customer?.location?.latitude ?? 0,
                  lng: serviceRequest?.customer?.location?.longitude ?? 0,
                };
              const distance = getDistanceLocation(SPLocation, ServiceRequestLocation);
              const diff = DateTime.fromISO(created).diff(DateTime.now(), ['days']);
              const createdDate = Math.abs(diff.toObject().days as number) > 4
                ? DateTime.fromISO(created).toLocaleString()
                : DateTime.now().setLocale('en').plus(diff).toRelativeCalendar();
              return (
                <NavLink key={id} to={`/service-request/detail/${id}`}>
                  <div className="dashboard__card" key={id}>
                    <div className="dashboard__card__header">
                      <div className="dashboard__card__date">
                        {createdDate}
                      </div>
                      {emergency && (<div className="indicator indicator--red" />)}
                    </div>
                    <div className="dashboard__card__client">
                      <span className="dashboard__card__client--name">
                        {customer?.person_title && (PERSON_TITLE[customer?.person_title] + ' ')}
                        {customer.first_name}&nbsp;{customer.last_name}
                      </span>
                      <span className="status-color">
                        {customer.status}
                      </span>
                    </div>
                    <div className="dashboard__card__type">
                      {category}
                    </div>
                    <div className="dashboard__card__footer">
                      <div className="client-id">
                        {'#'}{number}
                      </div>
                      <div className="dashboard__card__location">
                        <span className="text-nowrap">{distance}{' km'}</span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>

        <div className="dashboard__section dashboard__section--in-progress">
          <div className="dashboard__section__header">
            In progress
            <span className="mark mark--yellow">
              {serviceRequestList?.filter(serviceRequest => serviceRequest.status !== 'CANCELLED' && serviceRequest.status === 'IN_PROGRESS').length}
            </span>
          </div>
          {serviceRequestList?.filter(serviceRequest => serviceRequest.status !== 'CANCELLED' && serviceRequest.status === 'IN_PROGRESS')
            .map(serviceRequest => {
              const { id, customer, number, created, category, mark } = serviceRequest;
              const ServiceRequestLocation =
                {
                  lat: serviceRequest?.customer?.location?.latitude ?? 0,
                  lng: serviceRequest?.customer?.location?.longitude ?? 0,
                };
              const distance = getDistanceLocation(SPLocation, ServiceRequestLocation);
              const diff = DateTime.fromISO(created).diff(DateTime.now(), ['days']);
              const createdDate = Math.abs(diff.toObject().days as number) > 4
                ? DateTime.fromISO(created).toLocaleString()
                : DateTime.now().setLocale('en').plus(diff).toRelativeCalendar();
              return (
                <NavLink key={id} to={`/service-request/detail/${id}`}>
                  <div className="dashboard__card" key={id}>
                    <div className="dashboard__card__header">
                      <div className="dashboard__card__date">
                        {createdDate}
                      </div>
                      {mark && (<div className="indicator indicator--yellow" />)}
                    </div>
                    <div className="dashboard__card__client">
                      <span className="dashboard__card__client--name">
                        {customer?.person_title && (PERSON_TITLE[customer?.person_title] + ' ')}
                        {customer.first_name}&nbsp;{customer.last_name}
                      </span>
                      <span className="status-color">
                        {customer.status}
                      </span>
                    </div>
                    <div className="dashboard__card__type">
                      {category}
                    </div>
                    <div className="dashboard__card__footer">
                      <div className="client-id">
                        {'#'}{number}
                      </div>
                      <div className="dashboard__card__location">
                        <span className="text-nowrap">{distance}{' km'}</span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>

        <div className="dashboard__section dashboard__section--completed">
          <div className="dashboard__section__header">
            Completed
            <span className="mark mark--orange">{serviceRequestList?.filter(serviceRequest => serviceRequest.status === 'COMPLETED').length}</span>
          </div>
          {serviceRequestList?.filter(serviceRequest => serviceRequest.status === 'COMPLETED')
            .map(serviceRequest => {
              const { id, customer, number, created, category, emergency } = serviceRequest;
              const ServiceRequestLocation =
                {
                  lat: serviceRequest?.customer?.location?.latitude ?? 0,
                  lng: serviceRequest?.customer?.location?.longitude ?? 0,
                };
              const distance = getDistanceLocation(SPLocation, ServiceRequestLocation);
              const diff = DateTime.fromISO(created).diff(DateTime.now(), ['days']);
              const createdDate = Math.abs(diff.toObject().days as number) > 4
                ? DateTime.fromISO(created).toLocaleString()
                : DateTime.now().setLocale('en').plus(diff).toRelativeCalendar();
              return (
                <NavLink key={id} to={`/service-request/detail/${id}`}>
                  <div className="dashboard__card" key={id}>
                    <div className="dashboard__card__header">
                      <div className="dashboard__card__date">
                        {createdDate}
                      </div>
                    </div>
                    <div className="dashboard__card__client">
                      <span className="dashboard__card__client--name">
                        {customer?.person_title && (PERSON_TITLE[customer?.person_title] + ' ')}
                        {customer.first_name}&nbsp;{customer.last_name}
                      </span>
                      <span className="status-color">
                        {customer.status}
                      </span>
                    </div>
                    <div className="dashboard__card__type">
                      {category}
                    </div>
                    <div className="dashboard__card__footer">
                      <div className="client-id">
                        {'#'}{number}
                      </div>
                      <div className="dashboard__card__location">
                        <span className="text-nowrap">{distance}{' km'}</span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>

        <div className="dashboard__section dashboard__section--paid">
          <div className="dashboard__section__header">
            Paid
            <span className="mark mark--green">{serviceRequestList?.filter(serviceRequest => serviceRequest.status === 'PAID').length}</span>
          </div>
          {serviceRequestList?.filter(serviceRequest => serviceRequest.status === 'PAID')
            .map(serviceRequest => {
              const { id, customer, number, created, category, emergency } = serviceRequest;
              const ServiceRequestLocation =
                {
                  lat: serviceRequest?.customer?.location?.latitude ?? 0,
                  lng: serviceRequest?.customer?.location?.longitude ?? 0,
                };
              const distance = getDistanceLocation(SPLocation, ServiceRequestLocation);
              const diff = DateTime.fromISO(created).diff(DateTime.now(), ['days']);
              const createdDate = Math.abs(diff.toObject().days as number) > 4
                ? DateTime.fromISO(created).toLocaleString()
                : DateTime.now().setLocale('en').plus(diff).toRelativeCalendar();
              return (
                <NavLink key={id} to={`/service-request/detail/${id}`}>
                  <div className="dashboard__card" key={id}>
                    <div className="dashboard__card__header">
                      <div className="dashboard__card__date">
                        {createdDate}
                      </div>
                    </div>
                    <div className="dashboard__card__client">
                      <span className="dashboard__card__client--name">
                        {customer?.person_title && (PERSON_TITLE[customer?.person_title] + ' ')}
                        {customer.first_name}&nbsp;{customer.last_name}
                      </span>
                      <span className="status-color">
                        {customer.status}
                      </span>
                    </div>
                    <div className="dashboard__card__type">
                      {category}
                    </div>
                    <div className="dashboard__card__footer">
                      <div className="client-id">
                        {'#'}{number}
                      </div>
                      <div className="dashboard__card__location">
                        <span className="text-nowrap">{distance}{' km'}</span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DashboardGridViewWidget;
