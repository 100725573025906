// @ts-nocheck
import { useMutation, type UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';

export const useCreateInvoice = (id): UseMutationResult<unknown, unknown, unknown, unknown> => {
  const createInvoice = async (): AxiosPromise<AxiosResponse> => {
    return await apiClient.post(
      'provider/invoices/',
      { service_request_id: id },
    );
  };
  const queryClient = useQueryClient();
  const mutationOptions: {
    onError: (error: unknown) => void
    mutationFn: (payload: string) => AxiosPromise<AxiosResponse>
    onSuccess: (data: unknown) => void
  } = {
    mutationFn: createInvoice,
    onSuccess: (data) => {
      queryClient.setQueryData(['service-request', id], (detailServiceRequest) => ({
        ...detailServiceRequest,
        invoice_id: data?.data?.id,
      }));
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  };

  return useMutation(mutationOptions);
};
