export const TECHNICIAN_SCHEDULE = [
  {
    weekday: 1,
    weekday_title: 'Monday',
  },
  {
    weekday: 2,
    weekday_title: 'Tuesday',
  },
  {
    weekday: 3,
    weekday_title: 'Wednesday',
  },
  {
    weekday: 4,
    weekday_title: 'Thursday',
  },
  {
    weekday: 5,
    weekday_title: 'Friday',
  },
  {
    weekday: 6,
    weekday_title: 'Saturday',
  },
  {
    weekday: 7,
    weekday_title: 'Sunday',
  },
] as Array<{ weekday: number, weekday_title: string }>;

