import { apiClient } from '@shared/api/base';
import { type AxiosPromise, type AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { type UseMutateFunction, useMutation, type UseMutationOptions } from '@tanstack/react-query';
import { ROUTES } from '@shared/config/routes/constants';
import { localStorageGetItem, localStorageRemoveItem } from '@shared/lib/utils/localStorage';
import { STORAGE_KEYS } from '@pages/AuthPage/config/constants';

export const useLogOut = (): UseMutateFunction => {
  const navigate = useNavigate();

  const logOut = async (): AxiosPromise<AxiosResponse> => {
    const res = await apiClient.delete('provider/auth/logout/', {
      withCredentials: true,
      headers: {
        Authorization: `Token ${localStorageGetItem(STORAGE_KEYS.TOKEN)}`,
      },
    });
    return res.data;
  };

  const { mutate: logOutMutation } = useMutation({
    mutationFn: logOut,
    onSuccess: () => {
      localStorageRemoveItem(STORAGE_KEYS.TOKEN);
      navigate(ROUTES.AUTH);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  } as UseMutationOptions);

  return logOutMutation;
};
