// @ts-nocheck
import React, { useState, useEffect, type CSSProperties, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { PhoneInput } from 'react-international-phone';
import { DateTime } from 'luxon';
import classNames from 'classnames/bind';
import { MenuItem, Select, FormControl, TextareaAutosize, TextField, Autocomplete } from '@mui/material';
import plusIconViolet from '@shared/assets/Icons/icon_plus_accent.svg';

import { APIProvider } from '@vis.gl/react-google-maps';

import { SERVICE_CATEGORY_LIST } from '../../config/constants';

import { type IServiceRequestClient, type IPhoto, type IVideo } from '../../config/interfaces';

import { AddressMap, UploadPhoto, UploadVideo } from '../index';

import { useGetClientsList } from '../../model/useGetClientsList';

import styles from './styles.module.scss';
import {ICustomer} from "@pages/DashboardPage/config/interfaces";

const cx = classNames.bind(styles);

interface IRequestFormProps {
  id: string
  date: string
  photos: IPhoto[]
  setPhotos: (value: IPhoto[]) => void
  videos: IVideo[]
  setVideos: (value: IVideo[]) => void
  setLongitude: (arg: number) => void
  setLatitude: (arg: number) => void
  serviceRequestId: string
  selectedUser: ICustomer
  setSelectedUser: (customer: ICustomer) => void
}

interface IPhoneInputStyle extends CSSProperties {
  '--react-international-phone-height': string
  '--react-international-phone-border-radius': string
  '--react-international-phone-font-size': string
  '--react-international-phone-country-selector-background-color-hover': string
}

const RequestForm: React.FC<IRequestFormProps> = ({
  id,
  date,
  photos,
  setPhotos,
  videos,
  setVideos,
  setLongitude,
  setLatitude,
  serviceRequestId,
  selectedUser,
  setSelectedUser,
}) => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const { data } = useGetClientsList();
  const clientOptionsList: IServiceRequestClient[] = useMemo(() => (
    data
      ? data.results.map((client) => ({
        name: `${client.first_name} ${client.last_name}`,
        ...client,
      }))
      : []
  ), [data]);
  const clientOptionsForAutocomplete = clientOptionsList.map(client => { return { id: client.id, label: client.name }; });

  const methods = useFormContext();
  const { control, register, watch, formState: { errors }, setValue } = methods;

  useEffect(() => {
    if (selectedUserId) {
      const currentUser: IServiceRequestClient | undefined = clientOptionsList.find(user => user.id === selectedUserId);
      if (currentUser) {
        setValue('phone', currentUser.phone);
        setSelectedUser(currentUser);
        console.log(currentUser);
      }
    }
  }, [clientOptionsList, selectedUserId, setValue]);

  const requestDescriptionValue: string = watch().requestDescription;
  const additionalNotesValue: string = watch().additionalNotes;

  const handleUserChange = (event, value): void => {
    setSelectedUserId(value?.id);
  };

  return (
    <div className={cx('form-container')}>
      <div className={cx('form')}>
        <h2>New Service request</h2>
        <div className={cx('form__row__group')}>
          <div className={cx('form__row')}>
            <div className={cx('text-bolder')}>Service request ID</div>
            <div className={cx('text-bigger', 'text-bold')}>{`#${id}`}</div>
          </div>
          <div className={cx('form__row')}>
            <div className={cx('text-bolder')}>Created on</div>
            <div className={cx('text-bigger', 'text-bold')}>{DateTime.fromISO(date).toFormat('dd/LL/yyyy')}</div>
          </div>
        </div>
        <div className={cx('form__row__group')}>
          <div className={cx('form__row')}>
            <div className={cx('form__label')}>Client<span className={cx('required')}>*</span></div>
            <FormControl
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '48px',
                  '& fieldset': {
                    borderColor: 'var(--gray-middle)',
                    borderRadius: '8px',
                    borderWidth: '1px !important',
                    transition: '0.3s',
                  },
                  '&:hover': {
                    '& fieldset': {
                      borderColor: 'var(--gray-middle)',
                    },
                  },
                },
                '& .MuiAutocomplete-root': {
                  '.MuiAutocomplete-input': {
                    height: '100%',
                    padding: '0 6px!important',
                    border: 'none',
                  },
                  '.MuiAutocomplete-clearIndicator': {
                    marginRight: '4px',
                  },
                },
                '& .MuiAutocomplete-listbox': {
                  fontSize: '1.4rem!important',
                },
              }}
            >
              <Controller
                name="userName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  const { onChange, value, ref } = field;
                  return (<Autocomplete
                    {...field}
                    value={
                      value
                        ? clientOptionsForAutocomplete.find((option) => {
                          return value === option.id;
                        }) ?? null
                        : null
                    }
                    disablePortal
                    options={clientOptionsForAutocomplete}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      const resolvedId = newValue ? newValue.id : null;
                      onChange(resolvedId);
                      handleUserChange(event, newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="" inputRef={ref}/>}
                  />
                  );
                }}
              />
            </FormControl>
            {errors.userName && <div className={cx('error')}>Required field</div>}
            <button className={cx('button', 'button--text-small')}>
              <img src={plusIconViolet} width="20" height="20" alt="Add a new client"/>
              Add a new client
            </button>
          </div>
          <div className={cx('form__row')}>
            <div className={cx('form__label')}>Phone<span className={cx('required')}>*</span></div>
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <PhoneInput
                  disabled={!selectedUserId}
                  defaultCountry="ru"
                  forceDialCode
                  inputStyle={{
                    width: '100%',
                    height: '48px',
                    borderRadius: '8px',
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                    paddingLeft: '0',
                  }}
                  style={{
                    '--react-international-phone-height': '48px',
                    '--react-international-phone-border-radius': '8px',
                    '--react-international-phone-font-size': '14px',
                    '--react-international-phone-country-selector-background-color-hover': 'white',
                  } as IPhoneInputStyle}
                  {...field}
                />
              )}
            />
            {errors.phone && <div className={cx('error')}>Required field</div>}
          </div>
        </div>
        <APIProvider apiKey={import.meta.env.APP_GOOGLE_MAPS_KEY} libraries={['marker']}>
          <AddressMap
            selectedUser={selectedUser}
            setLongitude={setLongitude}
            setLatitude={setLatitude}
          />
        </APIProvider>
        <div className={cx('form__row')}>
          <div className={cx('form__label')}>Service category<span className={cx('required')}>*</span></div>
          <FormControl
            disabled={!selectedUserId}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'var(--gray-middle)',
                  borderRadius: '8px',
                  borderWidth: '1px !important',
                  transition: '0.3s',
                },
                '&:hover': {
                  '& fieldset': {
                    borderColor: 'var(--gray-middle)',
                  },
                },
              },
              '& .Mui-disabled': {
                '& fieldset': {
                  backgroundColor: 'var(--disabled-bg)',
                  border: '1px var(--gray-middle) solid !important',
                  marginTop: '-2px',
                  marginBottom: '-3px',
                },
              },
            }}
          >
            <Select
              className={cx('select-wrapper')}
              IconComponent={() => null}
              {...register('serviceCategory', {
                required: true,
              })}
            >
              {SERVICE_CATEGORY_LIST.map((user) => (
                <MenuItem key={user.id} value={user.value} className={cx('select-item')}>{user.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {/*{errors.serviceCategory && <div className={cx('error')}>Required field</div>}*/}
        </div>
        <div className={cx('form__row')}>
          <input
            id="emergency"
            type="checkbox"
            value=""
            disabled={!selectedUserId}
            {...register('emergency')}
          />
          <label htmlFor="emergency">
            Emergency
          </label>
        </div>
        <div className={cx('form__row')}>
          <div className={cx('form__label')}>Request description<span className={cx('required')}>*</span></div>
          <div className={cx('textarea-wrapper')}>
            <TextareaAutosize
              minRows={1}
              placeholder="Text..."
              disabled={!selectedUserId}
              style={{
                width: '100%',
                boxSizing: 'border-box',
                padding: '0px 12px',
                resize: 'none',
                alignContent: 'center',
              }}
              {...register('requestDescription', { maxLength: 500, required: true })}
            />
            <div className={cx('letter-counter')}>
              {`${requestDescriptionValue ? requestDescriptionValue.length : 0} / 500`}
            </div>
          </div>
          {errors.requestDescription && <div className={cx('error')}>The maximum length is 500 characters</div>}
        </div>
        <UploadVideo
          selectedUser={selectedUserId}
          videos={videos}
          setVideos={setVideos}
          serviceRequestId={serviceRequestId}
        />
        <UploadPhoto
          selectedUser={selectedUserId}
          photos={photos}
          setPhotos={setPhotos}
          serviceRequestId={serviceRequestId}
        />
        <div className={cx('form__row')}>
          <div className={cx('form__label')}>Additional notes</div>
          <div className={cx('textarea-wrapper')}>
            <TextareaAutosize
              minRows={1}
              placeholder="Contact person, keys location..."
              disabled={!selectedUserId}
              style={{
                width: '100%',
                boxSizing: 'border-box',
                padding: '0px 12px',
                resize: 'none',
                alignContent: 'center',
              }}
              {...register('additionalNotes', { maxLength: 500 })}
            />
            <div className={cx('letter-counter')}>
              {`${additionalNotesValue ? additionalNotesValue.length : 0} / 500`}
            </div>
          </div>
          {errors.additionalNotes && <div className={cx('error')}>The maximum length is 500 characters</div>}
        </div>
      </div>
    </div>
  );
};

export default RequestForm;
