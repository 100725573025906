// @ts-nocheck
import React, { useEffect } from 'react';
import './style.scss';
import { Controller, useWatch } from 'react-hook-form';
import { FormControl, MenuItem, Select } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import MultiSelect from '@widgets/MakeAppointmentWidget/ui/MultiSelect/MultiSelect';
import IconArrow from '@shared/assets/Icons/icon_arrow_down.svg?react';
import IconDelete from '@shared/assets/Icons/icon_delete.svg';
import { DateTime, Info, Settings } from 'luxon';
import { getGeneratedTimeArray } from '@shared/lib/utils/getGeneratedTimeArray';
Settings.defaultLocale = 'lb-LU';
Settings.defaultWeekSettings = {
  firstDay: 7,
  minimalDays: Info.getMinimumDaysInFirstWeek(),
  weekend: Info.getWeekendWeekdays(),
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function CustomCalendarHeader (props) {
  const { currentMonth, onMonthChange } = props;

  const selectNextMonth = () => { onMonthChange(currentMonth.plus({ months: 1 })); };
  const selectPreviousMonth = () => { onMonthChange(currentMonth.minus({ months: 1 })); };

  return (
    <div className="date-picker_calendar-header">
      <IconArrow className="date-picker_calendar-header_prev" onClick={selectPreviousMonth}/>
      <div className="date-picker_calendar-header_month">{currentMonth.monthLong}</div>
      <IconArrow className="date-picker_calendar-header_next" onClick={selectNextMonth}/>
    </div>
  );
}

const TimePeriod: React.FC = ({ control, setValue, index, remove, timePeriods, options }) => {
  const date = useWatch({
    control,
    name: `options[${index}].date`,
  });
  const defaultDate = date ? DateTime.fromFormat(date as string, 'yyyy-MM-dd') : DateTime.local();
  const today = DateTime.local().startOf('day');

  const isToday = (dateString: string): boolean => {
    const inputDate = DateTime.fromISO(dateString);
    const today = DateTime.now();
    return inputDate.hasSame(today, 'day');
  };

  const startTime = useWatch({
    control,
    name: `options[${index}].start_time`,
  });

  const endTime = useWatch({
    control,
    name: `options[${index}].end_time`,
  });

  // useEffect(() => {
  //   setValue(`options[${index}].technicians_ids`, []);
  // }, [date, startTime, endTime, setValue, index]);

  const technics = useWatch({
    control,
    name: `options[${index}].technicians_ids`,
  });

  const shouldDisableDate = (date): boolean => {
    return date < today;
  };
  const techniciansAutocomplete = options?.technicians_ids?.map(tech => {
    return {
      id: tech.id,
      label: tech.first_name + ' ' + tech.last_name,
      techStatus: tech.employment_status,
      first_name: tech.first_name,
      last_name: tech.last_name,
    };
  });

  return (
    <div className="time-period_form" style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <div className="form__row__group">
          <div className="form__row">
            <div className="form__label">Date<span className="required" style={{ color: '#e03137' }}>*</span></div>
            <FormControl
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'var(--gray-middle)',
                    borderRadius: '8px',
                    borderWidth: '1px !important',
                    transition: '0.3s',
                  },
                  '&:hover': {
                    '& fieldset': {
                      borderColor: 'var(--gray-middle)',
                    },
                  },
                  '& .MuiOutlinedInput-input': {
                    border: 'none',
                  },
                },
                '& .MuiPickersDay-root': {
                  color: 'red',
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <Controller
                  name={`options[${index}].date`}
                  control={control}
                  render={({ field: controllerField }) => (
                    <DatePicker
                      label=""
                      format="dd.MM.yyyy"
                      views={['day']}
                      shouldDisableDate={shouldDisableDate}
                      defaultValue={defaultDate}
                      onChange={(value) => {
                        const formattedDate = value.toFormat('yyyy-MM-dd');
                        controllerField.onChange(formattedDate.toString());
                        setValue(`options[${index}].technicians_ids`, []);
                      }}
                      dayOfWeekFormatter={(weekday) => weekday.weekdayShort}
                      slots={{ calendarHeader: (props) => <CustomCalendarHeader {...props}/> }}
                      slotProps={{
                        day: {
                          sx: {
                            '&.MuiPickersDay-root.Mui-selected': {
                              backgroundColor: '#6D438F',
                              color: 'white',
                            },
                            '&.MuiPickersDay-root': {
                              borderRadius: '8px',
                              '& :not(.Mui-selected)': {
                                borderColor: '#6D438F',
                              },
                            },
                          },
                        },
                        popper: {
                          sx: {
                            '& .MuiPaper-elevation.MuiPaper-rounded': {
                              borderRadius: '12px',
                              border: '1px solid #E8E8E8',
                              boxShadow: 'none',
                              marginTop: '8px',
                            },
                            '& .MuiPickersDay-dayWithMargin': {
                              color: '#171717',
                              fontSize: '16px',
                            },
                            '& .MuiTypography-root.MuiDayCalendar-weekDayLabel': {
                              fontSize: '14px',
                            },
                          },
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          <div className="form__row">
            <div className="form__label">
            Start time<span className="required" style={{ color: '#e03137' }}>*</span>
            </div>
            <FormControl
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'var(--gray-middle)',
                    borderRadius: '8px',
                    borderWidth: '1px !important',
                    transition: '0.3s',
                  },
                  '&:hover': {
                    '& fieldset': {
                      borderColor: 'var(--gray-middle)',
                    },
                  },
                },
                '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper': {
                  maxHeight: '300px',
                },
              }}
            >
              <Controller
                name={`options[${index}].start_time`}
                control={control}
                render={({ field: controllerField }) => (
                  <Select
                    className="select-wrapper"
                    IconComponent={() => null}
                    labelId={`select-label-${index}`}
                    label=""
                    placeholder="Select time"
                    {...controllerField}
                    onChange={(event) => {
                      controllerField.onChange(event);
                      setValue(`options[${index}].technicians_ids`, []);
                    }}
                  >
                    {
                      getGeneratedTimeArray(isToday(date), '', endTime).map(time => (
                        <MenuItem key={time} value={time} className="select-item">
                          {time}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
              />
            </FormControl>
          </div>
          <div className="form__row">
            <div className="form__label">End time<span className="required" style={{ color: '#e03137' }}>*</span></div>
            <FormControl
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'var(--gray-middle)',
                    borderRadius: '8px',
                    borderWidth: '1px !important',
                    transition: '0.3s',
                  },
                  '&:hover': {
                    '& fieldset': {
                      borderColor: 'var(--gray-middle)',
                    },
                  },
                },
              }}
            >
              <Controller
                name={`options[${index}].end_time`}
                control={control}
                render={({ field: controllerField }) => (
                  <Select
                    className="select-wrapper"
                    IconComponent={() => null}
                    labelId={`select-label-${index}`}
                    label=""
                    {...controllerField}
                    onChange={(event) => {
                      controllerField.onChange(event);
                      setValue(`options[${index}].technicians_ids`, []);
                    }}
                  >
                    {
                      getGeneratedTimeArray(isToday(date), startTime, '').map(time => (
                        <MenuItem key={time} value={time} className="select-item">
                          {time}
                        </MenuItem>
                      ))
                    }
                  </Select>
                )}
              />
            </FormControl>
          </div>
        </div>
        <div className="form__row">
          <div className="form__label" style={{ marginTop: '16px', marginBottom: '4px' }}>
          Assign technician<span className="required" style={{ color: '#e03137' }}>*</span>
          </div>
          <MultiSelect
            control={control}
            index={index}
            technicians={techniciansAutocomplete || []}
          />
        </div>
      </div>
      {timePeriods?.length !== 1 && (
        <div style={{ marginTop: '38px', marginLeft: '14px' }}>
          <button type="button" className="button button--small button--delete" onClick={() => {
            if (timePeriods?.length !== 1) {
              remove(index);
            }
          }}>
            <img src={IconDelete} width="32" height="32" alt="delete"/>
          </button>
        </div>
      )}
    </div>
  );
};

export default TimePeriod;
