// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { ESignInSteps } from '@pages/AuthPage/config/enums';

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import './style.scss';

import { useSignIn } from '@pages/AuthPage/model/useSignIn';

interface PhoneStepProps {
  setSignInStep: React.Dispatch<React.SetStateAction<ESignInSteps>>
  phone: string
  setPhone: React.Dispatch<React.SetStateAction<string>>
}

export const PhoneStep: React.FC<PhoneStepProps> = (props: PhoneStepProps) => {
  const { phone, setPhone, setSignInStep } = props;
  const [errorPhoneInput, setErrorPhoneInput] = useState('');
  const [isValid, setValid] = useState(true);
  const [isDisableSendBtn, setDisableSendBtn] = useState(true);

  const checkPhone = (phone: string): void => {
    setPhone(phone);
    if (phone) {
      setValid(isValidPhoneNumber(phone));
      setDisableSendBtn(!isValidPhoneNumber(phone));
    }
    setErrorPhoneInput('');
  };

  const { mutate: signIn, isSuccess, error, isError } = useSignIn();

  const onSubmitPhone = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    signIn({ phone });
  };

  useEffect(() => {
    if (isSuccess) {
      setSignInStep(ESignInSteps.SmsStep);
    }
    if (error) {
      // eslint-disable-next-line no-prototype-builtins
      if (error.response?.data?.errors?.hasOwnProperty('non_field_errors')) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setErrorPhoneInput(error.response?.data?.errors?.non_field_errors[0]);
      }
      // eslint-disable-next-line no-prototype-builtins
      if (error.response?.data?.errors?.hasOwnProperty('detail')) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setErrorPhoneInput(error.response?.data?.errors?.detail);
      }
    }
  }, [error, isSuccess, setSignInStep]);

  return (
    <form className="form" onSubmit={onSubmitPhone}>
      <h2>Login to your account</h2>
      <div className="form__message">
        Please provide your phone number. We will send you an SMS code to log in
      </div>
      <div className="form__row">
        <div className="form__label">Phone Number<span className="required">*</span></div>
        <PhoneInput
          defaultCountry="LU"
          value={phone}
          onChange={(e) => {
            checkPhone(e);
          }}
          style={{
            borderColor: `${!isValid ? 'var(--red)' : ''}`,
          }}
        />
        {!isValid &&
          <div className="error">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            This phone number doesn't seem correct. Please check the country code and number
          </div>
        }
        {isError && errorPhoneInput.length !== 0 &&
          <div className="error">{errorPhoneInput}</div>
        }
      </div>
      <Button type={'submit'}
        disabled={isDisableSendBtn}
        disableRipple
        sx={{
          font: '600 1.6rem/1.6 var(--font-main)',
          padding: '0 24px',
          marginTop: '32px',
          borderRadius: '8px',
          boxShadow: 'none !important',
          height: '48px',
          width: '100%',
          color: 'var(--white)',
          backgroundColor: 'var(--accent)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'all 0.25s linear',
          '&:hover': {
            backgroundColor: 'var(--accent-hover)',
          },
          '&:active': {
            backgroundColor: 'var(--accent-active)',
          },
        }}>Send SMS code</Button>
    </form>
  );
};
