// @ts-nocheck
import React, { type ChangeEvent, useState } from 'react';
import classNames from 'classnames/bind';

import plusIconViolet from '@shared/assets/Icons/icon_plus_accent.svg';

import { type IPhoto } from '@widgets/CreateServiceRequestWidget/config/interfaces';

import SliderPhoto from '@widgets/DetailServiceRequestWidget/ui/SliderPhoto';

import { useUploadMediaStart } from '../../model/useUploadMediaStart';
import { useUploadMediaProcess } from '../../model/useUploadMediaProcess';
import { useUploadPhotoFinish } from '../../model/useUploadPhotoFinish';
import { useDeleteUploadPhoto } from '../../model/useDeleteUploadPhoto';

import { MEDIA_TYPES } from '../../config/constants';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IUploadPhotoProps {
  photos: IPhoto[]
  setPhotos: (value: IPhoto[]) => void
  serviceRequestId: string
  selectedUser: string | null
}

export interface IImageDimensions {
  width: number
  height: number
}

const UploadPhoto: React.FC<IUploadPhotoProps> = ({ photos, setPhotos, serviceRequestId, selectedUser }) => {
  const [file, setFile] = useState<File | null>(null);
  const [imageDimensions, setImageDimensions] = useState<IImageDimensions | null>(null);
  const [key, setKey] = useState('');
  const [photoId, setPhotoId] = useState('');

  const { mutate: uploadPhotoFinish } = useUploadPhotoFinish({ photos, setPhotos });
  const { mutate: uploadMediaProcess } = useUploadMediaProcess({
    type: MEDIA_TYPES.IMAGE,
    serviceRequestId,
    dimensionsObj: imageDimensions,
    file,
    finishFunction: uploadPhotoFinish,
    key,
  });
  const { mutate: uploadMediaStart } = useUploadMediaStart({
    processFunction: uploadMediaProcess,
    file,
    setKeyFunction: setKey,
  });
  const { mutate: deleteUploadPhoto } = useDeleteUploadPhoto({ id: photoId, photos, setPhotos });

  const getImageDimensions = async (file: File): Promise<{ width: number, height: number }> => {
    return await new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const handlePhotoChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0];
    if (file) {
      setFile(file);
      const newPhoto = {
        file_name: file.name,
        service_request_id: serviceRequestId,
      };
      setImageDimensions(await getImageDimensions(file));
      uploadMediaStart(newPhoto);
    }
  };

  const handleRemoveImage = (index: string): void => {
    deleteUploadPhoto(index);
  };

  return (
    <div className={cx('form__row')}>
      <div className={cx('form__label')}>Photo</div>
      <div className={cx('form__text')}>You can upload 6 files</div>
      <div className={cx('files-set')}>
        {photos.length > 0 &&
            <SliderPhoto
              images={photos}
              createServiceRequest
              setPhotoId={setPhotoId}
              handleRemoveImage={handleRemoveImage}
            />
        }
        <div className={cx('input--file', (photos.length > 5 || !selectedUser) ? 'is-disabled' : '')}>
          <label
            htmlFor="uploadPhoto"
            className={cx('button')}
          >
            <img src={plusIconViolet} width="24" height="24" alt="Add media"/>
            Add media
          </label>
          <input
            type="file"
            accept="image/*"
            id="uploadPhoto"
            disabled={photos.length > 5}
            style={{ display: 'none' }}
            onChange={handlePhotoChange}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadPhoto;
