import { type ITypeOfWork } from './interfaces';

export const PERSON_TITLE = {
  mr: 'Mr.',
  mrs: 'Mrs.',
  ms: 'Ms.',
};

export const TYPE_OF_WORK_LIST: ITypeOfWork[] = [
  {
    id: '0',
    value: 'empty',
    label: 'Type of work',
  },
  {
    id: '1',
    value: 'MAINTENANCE',
    label: 'Maintenance',
  },
  {
    id: '2',
    value: 'REPAIR',
    label: 'Repair',
  },
  {
    id: '3',
    value: 'PROJECT',
    label: 'Project',
  },
  {
    id: '4',
    value: 'OTHER',
    label: 'Other',
  },
];
